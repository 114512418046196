import { type ReactNode } from 'react'

import { useCurrent } from '@/current'

type Props = {
  children: ReactNode
}

export default function DevelopmentOnly({ children }: Props) {
  const { development } = useCurrent()

  return development ? children : null
}
